export default async (errorMessage) => {
  const urlParts = window.location.pathname.split("/");
  const uuid = urlParts[3];
  fetch("/next_of_kin/report_error", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")?.content,
    },
    body: JSON.stringify({
      error_message: `${errorMessage}. UUID: ${uuid}`,
    }),
  });
};
