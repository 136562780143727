import sendSentryError from "./sendSentryError";

export default (_options) => {
  document.querySelectorAll(".view-document").forEach((elem) => {
    elem.classList.add("disabled");
    elem.innerHTML = '<div class="spinner-loader"><div class="spinner"></div></div>';
  });

  var url = document.getElementById("surveyContainer").getAttribute("data-response-documents-url");
  var requiredWaDocuments = JSON.parse(
    document.getElementById("surveyContainer").getAttribute("data-response-required-wa-documents")
  );

  const request = new XMLHttpRequest();
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    request.open("POST", url);
    request.setRequestHeader(
      "X-CSRF-Token",
      document.querySelector("meta[name=csrf-token]")?.content
    );
    request.send();
    attempts++;

    request.onreadystatechange = () => {
      // readyState === 4 means operation is complete as per: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
      if (request.readyState === 4) {
        if (
          request.status === 200 && JSON.parse(request.response).documents
            ? requiredWaDocuments.every((i) =>
                Object.keys(JSON.parse(request.response).documents).includes(i)
              )
            : false
        ) {
          const docs = JSON.parse(request.response).documents;
          document.querySelector(".cremation-form-pdf").href = docs[requiredWaDocuments[0]];
          document.querySelector(".id-form-pdf").href = docs[requiredWaDocuments[1]];
          document.querySelector(".fourth-schedule-form-pdf").href = docs[requiredWaDocuments[2]];
          document.querySelectorAll(".view-document").forEach((elem) => {
            elem.innerHTML = "View document";
            elem.classList.remove("disabled");
          });
        } else if (attempts >= 5) {
          const errorCard = document.createElement("div");
          errorCard.classList.add("error-card-review-pdfs");
          errorCard.innerHTML =
            "Something went wrong while fetching your documents. Please try again later, or ask your arranger to provide these documents.";

          document
            .querySelector(".pdf-details-table")
            .parentNode.insertBefore(
              errorCard,
              document.querySelector(".pdf-details-table").nextSibling
            );

          document.querySelectorAll(".view-document").forEach((elem) => {
            elem.innerHTML = "View document";
          });

          // Double, but we want to leave the old way for now.
          sendSentryError("Exceeded max (5) attempts in getting WA docs to review.");
          throw getError("Exceeded max (5) attempts in getting WA docs to review.");
        } else {
          setTimeout(executePoll, 5000, resolve, reject);
        }
      }
    };
  };

  const getError = (error) => {
    let newError = new Error(error);
    newError.name = `NokPaperwork: ${error}`;

    return newError;
  };

  executePoll();
};
